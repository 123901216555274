import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue")
  },
  {
    path: "/galleria",
    name: "galleria",
    component: () => import("../views/GalleriaView.vue")
  },
  { path: "/portfolio",
    name: "portfolio",
    component: () => import("../views/PortfolioView.vue")
  },
  { path: "/crea-modifica-opera",
    name: "crea-modifica-opera",
    component: () => import("../views/OperaNewEdit.vue")
  },
  { path: "/dettaglio-opera",
    name: "dettaglio-opera",
    component: () => import("../views/OperaDetail.vue")
  },
  { path: "/gestione-profilo",
    name: "gestione-profilo",
    component: () => import("../views/GestioneProfiloView.vue")
  },
  { path: "/profilo-artista",
    name: "profilo-artista",
    component: () => import("../views/ProfiloArtistaView.vue")
  },
  { path: "/gestione-utenti",
    name: "gestione-utenti",
    component: () => import("../views/GestioneUtentiView.vue")
  },
  { path: "/crea-modifica-utente",
    name: "crea-modifica-utente",
    component: () => import("../views/UtenteNewEdit.vue")
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes
})

export default router;
